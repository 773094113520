import React from "react";
import "./GamesList.css"; // Import the CSS for styling

const games = [
    { id: 1, name: "Hangman", image: "https://i.ibb.co/Br79SHT/Hangam.png", url: "https://biblophile.com/hangman" },
    { id: 2, name: "Quiz", image: "https://i.ibb.co/Chm2PCP/Question-mark.png", url: "https://biblophile.com/quiz" },
];

const GamesList = () => {
    return (
        <>
            <h2 className="game-heading">Games</h2>
            <div className="games-list">
                {games.map((game) => (
                    <div key={game.id} className="game-item" onClick={() => window.location.href = game.url}>
                        <img src={game.image} alt={game.name} className="game-image" />
                        <h3 className="game-name">{game.name}</h3>
                    </div>
                ))}
            </div>
        </>
    );
};

export default GamesList;
