import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./BookDetail.css";
import OrderPage from "./OrderPage";

function BookDetail({ bookInfo, closeDiv, onResetSearch }) {
    const [visibility, setVisibility] = useState(false);
    const [isRent, setIsRent] = useState(false);
    const [price, setPrice] = useState(null);

    let rentAmount = Math.max(25, Math.min(35, Math.floor(bookInfo.BookPrice * 0.1)));

    const toggleVisibility = () => {
        setVisibility(!visibility);
    }

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    }

    const isMobile = () => {
        return window.innerWidth <= 768;
    }

    const placeOrder = (orderMode) => {
        if (bookInfo.BookmarkId) {
            toggleVisibility();
            setPrice(bookInfo.BookmarkPrice);
        } 
        else {
            if (orderMode === "rent") {
                // Security deposit amount is static now. We need to address this in the future.
                const confirmationText = `1. We will charge ₹300 as a security deposit, which will be returned to you.
                    \n2. Renting Books: Orders < ₹120 incur ₹90 for delivery and pickup; otherwise, both are free.
                `;

                if (window.confirm(confirmationText)) {
                    toggleVisibility();
                    setIsRent(true);
                    setPrice(rentAmount);
                }
            }
            else {
                const confirmationText = `1. Buying Books: Orders < ₹120 incur ₹50 delivery fee; otherwise, delivery is free.

                `;

                if (window.confirm(confirmationText)) {
                    toggleVisibility();
                    setPrice(bookInfo.BookPrice);
                }
            }
        }
    }

    const handleResetSearch = () => {
        if (onResetSearch){
            onResetSearch();
        }
    }

    return (
        <div className="bookDetailContainer">
            <div className="closeButtonContainer">
                <p className="closeButton" onClick={closeDiv}><b>X</b></p>
            </div>
            <div className="bookDetail">
                <div className="bookPoster">
                    <img
                        className="bookPosterImage"
                        src={bookInfo.BookmarkPoster ? bookInfo.BookmarkPoster : bookInfo.BookPoster} alt="Book"/>
                </div>
                {!visibility && <div className="bookDescription">
                    <h1>{bookInfo.BookmarkTitle ? bookInfo.BookmarkTitle : bookInfo.BookName}</h1>
                    {bookInfo.BookmarkId ? <h5>Price: ₹{bookInfo.BookmarkPrice}</h5> : <h5>Book Price: <span className="price_text">₹{bookInfo.BookPrice}</span></h5>}
                    {isMobile() ? truncateText(bookInfo.BookmarkDescription ? bookInfo.BookmarkDescription : bookInfo.BookDescription, 150) : (bookInfo.BookmarkDescription ? bookInfo.BookmarkDescription : bookInfo.BookDescription)}
                    <NavLink to={bookInfo.BookmarkId ? `/product/?type=Bookmark&id=${bookInfo.BookmarkId}` : bookInfo.GoogleBookId ? `/product/?type=ExternalBook&id=${bookInfo.GoogleBookId}` : `/product/?type=Book&id=${bookInfo.BookId}`} className="product_link">
                        <span className="product_link_text" onClick={()=>handleResetSearch()}>More details...</span>
                    </NavLink>
                    <div className="buttons">
                        {!bookInfo.GoogleBookId && <button className="order_button" onClick={() => placeOrder("buy")}>Buy | ₹{!bookInfo.BookmarkId ? bookInfo.BookPrice : bookInfo.BookmarkPrice} </button>}
                        {!bookInfo.BookmarkId && !bookInfo.GoogleBookId && <button className="order_button" onClick={() => placeOrder("rent")}>Rent | ₹{rentAmount}</button>}
                    </div>
                </div>}
                {visibility && <OrderPage cancelOrder={toggleVisibility} isRent={isRent} bookName={bookInfo.BookmarkTitle ? bookInfo.BookmarkTitle : bookInfo.BookName} bookImage={bookInfo.BookmarkPhoto ? bookInfo.BookmarkPhoto : bookInfo.BookPoster} price={price}/>}
            </div>
        </div>
    );
}

export default BookDetail;
