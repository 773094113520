import React, { useState, useEffect } from "react";
import axios from "../services/axios";
import requests from "../services/requests";
import "./BookmarkRow.css";
import BookDetail from "./BookDetail";

function BookmarkRow () {
    const [bookmarks, setBookmarks] = useState( [] );
    const [bookmarkDetails, setBookmarkDetails] = useState( "" );

    useEffect( () =>{
        async function fetchData() {
            try {
                const response = await axios(requests.getBookmarks);
                setBookmarks(bookmarks.push = response.data);
              } catch (error) {
                console.log(error);
              }
        }
        fetchData();
    }, []); 

    const handleClick = ({bookmark}) => {
        setBookmarkDetails(bookmark);
    }

    const handleCloseDiv  = () => {
        setBookmarkDetails("");
    }

    return (
        <div className="row">
            <h2>Smart Bookmarks</h2>
            <div className="row_posters">
                {bookmarks.map(bookmark => (
                    <img
                      onClick={() => handleClick({bookmark})}
                      className={`row_poster`}
                      src={bookmark.BookmarkPhoto}
                      alt="Bookmark"/>
                ))}
            </div>
            {bookmarkDetails !== "" && <BookDetail bookInfo={bookmarkDetails} closeDiv={handleCloseDiv}/>}
        </div>
    )
}

export default BookmarkRow;