import React, { useState, useContext } from "react";
import axios from "../../services/axios";
import requests from "../../services/requests";

import { UserContext } from '../../contexts/UserContext';
import SEO from "../../services/seo";

import "./Settings.css";

function Settings() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [pass, setPass] = useState('');
    const [passCnf, setPassCnf] = useState('');

    const { user, setUser } = useContext(UserContext);

    const handleNameChange = (e) => {
        setName(e.target.value);
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    }
    const handlePassChange = (e) => {
        setPass(e.target.value);
    }
    const handlePassCnf = (e) => {
        setPassCnf(e.target.value);
    }

    const handleItemChange = (propertyName, updatedValue) => {
        if (updatedValue === "") {
            alert("Input field is empty!");
            return;
        }
        if (propertyName === "UserPassword") {
            if (pass !== passCnf) {
                alert("Passwords don't match");
                return;
            }
        }
        async function fetchData() {
            try {
                const response = await axios.post(requests.updateUserData, {
                    userId: user,
                    property: propertyName,
                    value: updatedValue,
                });
                if (response.data.message === "Updated") {
                    alert("Updated");
                    setEmail("");
                    setName("");
                    setPass("");
                    setPassCnf("");
                    setPhone("");
                } else {
                    alert(response.data.message);
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }

    return (
        <div className="settings-container">
            <SEO
                title="User Settings - Biblophile"
                description="Manage your account settings on Biblophile. Update your name, email, phone number, and password securely."
                name="Biblophile"
                type="profile"
                image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
            />
            <h1 className="settings-title">Settings</h1>
            <div className="settings-content">
                <div className="setting">
                    <div className='input-box'>
                        <input type='text' placeholder='Name' required value={name} onChange={handleNameChange} />
                    </div>
                    <button className="button" onClick={() => handleItemChange("UserName", name)}>Update Name</button>
                </div>
                <div className="setting">
                    <div className='input-box'>
                        <input type='email' placeholder='Email id' required value={email} onChange={handleEmailChange} />
                    </div>
                    <button className="button" onClick={() => handleItemChange("UserEmail", email)}>Update Email</button>
                </div>
                <div className="setting">
                    <div className='input-box'>
                        <input type='tel' pattern='[0-9]{10}' placeholder='Phone' required value={phone} onChange={handlePhoneChange} />
                    </div>
                    <button className="button" onClick={() => handleItemChange("UserPhone", phone)}>Update Phone</button>
                </div>
                <div className="setting">
                    <div className='input-box'>
                        <input type='password' placeholder='New Password' required value={pass} onChange={handlePassChange} />
                    </div>
                    <div className='input-box'>
                        <input type='password' placeholder='Confirm password' required value={passCnf} onChange={handlePassCnf} />
                    </div>
                    <button className="button" onClick={() => handleItemChange("UserPassword", pass)}>Update Password</button>
                </div>
            </div>
        </div>
    );
}

export default Settings;