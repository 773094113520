import React from "react";

import SEO from "../../services/seo";

import Banner from "../Banner";
import Row from "../Row";
import BookmarkRow from "../BookmarkRow";
import GamesList from "../GamesList";

function Home() {
    return (
        <>
            <SEO
                title='Home - Bibliophile'
                description='Discover a wide range of books across various genres including classics, fantasy, romance, and non-fiction on Bibliophile.'
                name='Bibliophile'
                type='website'
                image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
            />
            <Banner />

            <Row title= "Classics" />
            <BookmarkRow />
            <Row title= "Fantasy" />
            <GamesList />
            <Row title= "Romance" />
            <Row title= "Historical Fiction" />
        </>
    );
  }
  
  export default Home;