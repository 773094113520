import React, { useState, useEffect } from 'react';
import axios from "../../services/axios";
import requests from "../../services/requests";
import Rating from 'react-rating-stars-component';
import OrderPage from '../OrderPage';
import './ProductDetailsPage.css';
import ProductReviews from '../ProductReviews';
import BookEmotions from '../BookEmotions';
import ReadingStatus from '../ReadingStatus';
import SEO from '../../services/seo';

const ProductDetailsPage = () => {
    const [visibility, setVisibility] = useState(false);
    const [isRent, setIsRent] = useState(false);
    const [activeTab, setActiveTab] = useState('description');
    const [product, setProduct] = useState([]);
    const [id, setId] = useState();
    const [type, setType] = useState();
    const [subscription, setSubscription] = useState(false);
    const [averageRating, setAverageRating] = useState([]);
    const [averageEmotions, setAverageEmotions] = useState([]);
    const [isGoogleBook, setIsGoogleBook] = useState(false);

    const toggleVisibility = () => {
        setVisibility(!visibility);
    }

    const getPrices = () => {
        if (type === 'Book') {
            return [
                { size: 'Buy', price: product['ProductPrice'], currency: '₹' },
                {
                    size: 'Rent',
                    price: subscription ? 0 : Math.max(25, Math.min(35, Math.floor(product['ProductPrice'] * 0.1))),
                    currency: '₹',
                },
            ];
        } else if (type === 'Bookmark') {
            return [
                { size: 'QR', price: Math.ceil(product['ProductPrice']), currency: '₹' },
                { size: 'QR & NFC', price: Math.floor(product['ProductPrice'] * 1.3), currency: '₹' },
            ];
        }
        return [];
    };

    const [actualPrice, setActualPrice] = useState(0);
    const [prices, setPrices] = useState(getPrices());
    const [price, setPrice] = useState(prices[0] || { size: '', price: 0, currency: '₹' });

    const handleButtonClick = (data) => {
        if (data.size === "Rent") {
            const text = `1. We will charge ₹300 as a security deposit, which will be returned to you.
                    \n2. Renting Books: Orders < ₹120 incur ₹90 for delivery and pickup; otherwise, both are free.
                `;

            if (window.confirm(text)) {
                setVisibility(true);
                setIsRent(true);
                setPrice(data.price);
            }
        } else {
            setVisibility(true);
            setIsRent(false);
            setPrice(data.price);
        }
    };

    useEffect(() => {
        async function fetchProductDetails() {
            try {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                setId(params.id);
                setType(params.type);

                let response;
                if (params.type === 'ExternalBook') {
                    response = await axios(`${requests.fetchExternalBookDetails}${params.id}`);
                    // response = await axios(`https://www.googleapis.com/books/v1/volumes/${params.id}`);
                    // // response = await axios(`https://www.googleapis.com/books/v1/volumes/${params.id}&key=AIzaSyBbA065umVCvOmPW5GB7i2iS0Il3HD1uL4`);    //temporary api key addition to circumvent rate limit
                    setIsGoogleBook(true);
                } else {
                    response = await axios(`${requests.fetchProductDetails}${params.id}&type=${params.type}`);
                    setIsGoogleBook(false);
                }

                const data = response.data;
                setProduct(data);
                setActualPrice(data.ProductPrice || data.saleInfo?.listPrice?.amount || 0);
                const updatedPrices = getPrices();
                setPrices(updatedPrices);
                setPrice(updatedPrices[0] || { size: '', price: 0, currency: '₹' });
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        }

        fetchProductDetails();
    }, [actualPrice]);

    useEffect(() => {
        async function fetchActivePlan() {
            try {
                const response = await axios(requests.fetchActivePlan + localStorage.getItem("user"));
                const data = response.data;
                if (data[0].PlanId !== null) {
                    setSubscription(true);
                }
            } catch (error) {
                console.error('Error fetching plans:', error);
            }
        }

        fetchActivePlan();
    }, []);

    useEffect(() => {
        async function fetchAverageRating() {
            try {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());

                let id = params.id;

                if (params.type === "ExternalBook") {
                    // Fetch the BookId based on ISBN13 in case of google books
                    const isbn = product.volumeInfo?.industryIdentifiers?.find(id => id.type === 'ISBN_13')?.identifier || '';
                    if (isbn) {
                        const bookIdResponse = await axios.post(requests.fetchBookId, { ISBN: isbn });
                        if (bookIdResponse.data.bookId) {
                            id = bookIdResponse.data.bookId;
                        } else {
                            console.log("Failed to fetch BookId");
                            return;
                        }
                    }
                }
                const response = await axios(`${requests.fetchAverageRating}${id}`);
                const data = response.data;
                setAverageRating(data);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        }

        fetchAverageRating();
    }, [product]);

    useEffect(() => {
        async function fetchAverageEmotions() {
            try {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());

                let id = params.id;

                if (params.type === "ExternalBook") {
                    // Fetch the BookId based on ISBN13 in case of google books
                    const isbn = product.volumeInfo?.industryIdentifiers?.find(id => id.type === 'ISBN_13')?.identifier || '';
                    if (isbn) {
                        const bookIdResponse = await axios.post(requests.fetchBookId, { ISBN: isbn });
                        if (bookIdResponse.data.bookId) {
                            id = bookIdResponse.data.bookId;
                        } else {
                            console.log("Failed to fetch BookId");
                            return;
                        }
                    }
                }
                const response = await axios(`${requests.fetchAverageEmotions}${id}`);
                const data = response.data;
                setAverageEmotions(data.topEmotions);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        }

        fetchAverageEmotions();
    }, [product]);

    return (
        <>
        <SEO
                title={isGoogleBook ? product.volumeInfo?.title : product.ProductName}
                description='Discover a wide range of books across various genres including classics, fantasy, romance, and non-fiction on Bibliophile.'
                name='Bibliophile'
                type='website'
                image={isGoogleBook ? product.volumeInfo?.imageLinks?.thumbnail : product.ProductPhoto}
        />
        <div className="product-details-page">
            {type !== "Bookmark" && <ReadingStatus id={id} isGoogleBook={isGoogleBook} product={product}/>}
            <div className="image-and-buttons">
                <img className="book-image" src={isGoogleBook ? product.volumeInfo?.imageLinks?.thumbnail : product.ProductPhoto} alt={product.volumeInfo?.title || product.ProductName} />
                {product.ProductAvailability === '1' && <div className="product-buttons">
                    {prices.map((data, index) => (
                        <button key={index} onClick={() => handleButtonClick(data)}>{`${data.size} | ${data.currency}${data.price}`}</button>
                    ))}
                </div>}
            </div>
            <div className="book-info">
                <h1 className="book-title">{isGoogleBook ? product.volumeInfo?.title : product.ProductName}</h1>
                <div className="book-meta">
                    <p className="book-genres">{isGoogleBook ? product.volumeInfo?.categories?.join(", ") : product.ProductGenres}</p>
                    <p className="book-author">{isGoogleBook ? product.volumeInfo?.authors?.join(", ") : product.ProductAuthor}</p>
                </div>
                <div className="book-rating-emotions">
                {type !== "Bookmark" && averageRating.averageRating > 0 ? <div className="book-rating">
                    <Rating 
                        value={averageRating.averageRating} 
                        count={5}
                        edit={false}
                        size={30}
                        isHalf={true}
                        color={"#AEAEAE"}
                        activeColor={"#D17842"}
                    /> 
                    </div> : 
                    <div className='no-rating-text'>No ratings yet</div> }
                    {type !== "Bookmark" && averageEmotions && <div className="book-emotions">
                        {averageEmotions && averageEmotions.map((emotion, index) => (
                            <span key={index}>{emotion.Emotion}({Math.round(emotion.AvgScore)}%), </span>
                        ))}
                    </div>}
                </div>
                {visibility && <OrderPage cancelOrder={toggleVisibility} isRent={isRent} bookName={isGoogleBook ? product.volumeInfo?.title : product.ProductName} bookImage={isGoogleBook ? product.volumeInfo?.imageLinks?.thumbnail : product.ProductPoster} price={price}/>}
                <div className="book-tabs">
                    <button
                        className={`book-tab ${activeTab === 'description' ? 'active' : ''}`}
                        onClick={() => setActiveTab('description')}
                    >
                        Description
                    </button>
                    {type !== "Bookmark" && <button
                        className={`book-tab ${activeTab === 'reviews' ? 'active' : ''}`}
                        onClick={() => setActiveTab('reviews')}
                    >
                        Reviews & Ratings
                    </button>}
                    {type !== "Bookmark" && <button
                        className={`book-tab ${activeTab === 'moods' ? 'active' : ''}`}
                        onClick={() => setActiveTab('moods')}
                    >
                        Moods
                    </button>}
                </div>
                <div className={`book-content ${activeTab}`}>
                    <div className={`book-description ${activeTab === 'description' ? 'active' : ''}`}>
                        <p className='description-text'>{isGoogleBook ? product.volumeInfo?.description : product.ProductDescription}</p>
                    </div>
                    {type !== "Bookmark" && <div className={`book-reviews ${activeTab === 'reviews' ? 'active' : ''}`}>
                        <h2>Reviews & Ratings</h2>
                        {id && <ProductReviews id={id} isGoogleBook={isGoogleBook} product={product}/>}
                    </div>}
                    {type !== "Bookmark" && <div className={`book-moods ${activeTab === 'moods' ? 'active' : ''}`}>
                        <h2>Emotions</h2>
                        {id && <BookEmotions id={id} isGoogleBook={isGoogleBook} product={product}/>}
                    </div>}
                </div>
            </div>
        </div>
        </>
    );
};

export default ProductDetailsPage;
