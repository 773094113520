import React from 'react';
import './Footer.css'; 

/**
 * Represents the Footer component.
 * @function Footer
 * @returns {JSX.Element} JSX element representing the footer.
 * @throws {Error} If there is an issue rendering the footer.
 */
const Footer = () => {
  return (
    <footer>
      <p>©Biblophile 2024 &nbsp; <a href='https://biblophile.com/policies/privacy-policy.php'>Privacy Policy</a> &nbsp; <a href='https://biblophile.com/policies/customer-support.php'>Customer Support</a></p>
    </footer>
  );
};

export default Footer;