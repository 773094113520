import React, { useEffect, useState } from 'react';
import instance from '../../services/axios';
import requests from '../../services/requests';
import BookshelfCard from '../BookshelfCard';
import './ProfilePage.css';
import SEO from '../../services/seo';
import Mascot from '../Mascot';

const ProfilePage = () => {
  const [userBooks, setUserBooks] = useState([]);
  const [userAverageRating, setUserAverageRating] = useState(null);
  const [userAverageEmotions, setUserAverageEmotions] = useState([]);
  const [averageReadingDays, setAverageReadingDays] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserBooks = async () => {
      try {
        const response = await instance.post(requests.fetchUserBooks, {
          userId: localStorage.getItem('user'),
        });
        setUserBooks(response.data.userBooks);
      } catch (error) {
        console.error('Failed to fetch user books:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserBooks();
  }, []);

  useEffect(() => {
    const fetchAverageRatingByUser = async () => {
      try {
        const response = await instance(requests.fetchAverageRatingByUser+localStorage.getItem('user'));
        setUserAverageRating(response.data.averageRating);
      } catch (error) {
        console.error('Failed to fetch user rating:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAverageRatingByUser();
  }, []);

  useEffect(() => {
    const fetchAverageEmotionsByUser = async () => {
      try {
        const response = await instance(requests.fetchAverageEmotionsByUser+localStorage.getItem('user'));
        if (response.data.topEmotions) {
          setUserAverageEmotions(response.data.topEmotions);
        }
      } catch (error) {
        console.error('Failed to fetch user emotions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAverageEmotionsByUser();
  }, []);

  useEffect(() => {
    const fetchAverageDaystoFinish = async () => {
      try {
        const response = await instance(requests.fetchAverageDaystoFinish+localStorage.getItem('user'));
        setAverageReadingDays(response.data.averageDaysToFinish);
      } catch (error) {
        console.error('Failed to fetch average reading days:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAverageDaystoFinish();
  }, []);

  const currentlyReading = userBooks.filter(
    (book) => book.Status === 'Currently reading'
  );
  const recentlyRead = userBooks.filter((book) => book.Status === 'Read');
  const toBeRead = userBooks.filter((book) => book.Status === 'To be read');

  const formattedMoodPreferences = userAverageEmotions
    .map((mood) => mood.Emotion)
    .join(', ');

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <SEO
          title="Reading Journal - Biblophile"
          description="View the summary of your reading journal."
          name='Biblophile'
          type='website'
          image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
      />
      <div className="profile-page">
        <div className="books-section">
          {userBooks.length === 0 && <Mascot emotion="pendingBooks"/>}
          {currentlyReading.length > 0 && <section className="section currently-reading">
            <h2>Currently Reading</h2>
            <div className="books-list">
              {currentlyReading.map((book) => (
                <BookshelfCard
                  key={book.BookId}
                  product={book}
                  id={book.BookId}
                  photo={book.BookPhoto}
                  status={book.Status}
                  startDate={book.StartDate}
                  endDate={book.EndDate}
                  currentPage={book.CurrentPage}
                />
              ))}
            </div>
          </section>}

          {recentlyRead.length > 0 && <section className="section recently-read">
            <h2>Read Recently</h2>
            <div className="books-list">
              {recentlyRead.map((book) => (
                <BookshelfCard
                  key={book.BookId}
                  product={book}
                  id={book.BookId}
                  photo={book.BookPhoto}
                  status={book.Status}
                  startDate={book.StartDate}
                  endDate={book.EndDate}
                  currentPage={book.CurrentPage}
                />
              ))}
            </div>
          </section>}

          {toBeRead.length > 0 && <section className="section to-be-read">
            <h2>To Be Read</h2>
            <div className="books-list">
              {toBeRead.map((book) => (
                <BookshelfCard
                  key={book.BookId}
                  product={book}
                  id={book.BookId}
                  photo={book.BookPhoto}
                  status={book.Status}
                  startDate={book.StartDate}
                  endDate={book.EndDate}
                  currentPage={book.CurrentPage}
                />
              ))}
            </div>
          </section>}
        </div>

        <div className="info-section">
          <section className="section mood-preferences">
            <h2>Mood Preferences</h2>
            {userAverageEmotions.length === 0 ? <p>Sufficient data not available.</p> : <p>Prefers to read books which evoke <span className='highlight-text'>{formattedMoodPreferences}</span>.</p>}
          </section>

          <section className="section summary">
            <div className="summary-item average-days">
              <h2>Average Days to Finish a Book</h2>
              <p><span className='highlight-text'>{averageReadingDays}</span> days</p>
            </div>

            <div className="summary-item average-rating">
              <h2>Average Rating</h2>
              <p><span className='highlight-text'>{userAverageRating}</span> / 5</p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;