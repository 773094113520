import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaChartBar, FaShoppingCart, FaUser } from 'react-icons/fa';
import './BottomTab.css';

function BottomTab() {
  return (
    <div className="bottom-bar">
      <NavLink to="/" className="bottom-bar-item">
        <FaHome />
        <span>Home</span>
      </NavLink>
      <a href="/readingStreaks" className="bottom-bar-item">
        <FaChartBar />
        <span>Stats</span>
      </a>
      <NavLink to="/orders" className="bottom-bar-item">
        <FaShoppingCart />
        <span>Orders</span>
      </NavLink>
      <NavLink to="/profile" className="bottom-bar-item">
        <FaUser />
        <span>Profile</span>
      </NavLink>
    </div>
  );
}

export default BottomTab;
