import React, { useState, useEffect } from 'react';
import instance from '../../services/axios';
import requests from '../../services/requests';
import './SubscriptionPage.css';
import SEO from '../../services/seo';

const SubscriptionPage = () => {
    const [name , setName] = useState('');
    const [phone , setPhone] = useState('');
    const [plans, setPlans] = useState([]);
    const [deposit, setDeposit] = useState('');
    const [activePlan, setActivePlan] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchUserData = () => {
        async function fetchData() {
            try {
                const response = await instance.post(requests.fetchUserData, {
                    userId: localStorage.getItem('user')
                  });
                if (response.data.message === 1)
                {
                    if (response.data.name) {
                        setName(response.data.name);
                    }
                    if (response.data.phone) {
                        setPhone(response.data.phone);
                    }
                }
              } catch (error) {
                console.log(error);
              }
        }
        fetchData();
    }

    const handleOnlinePayment = async (planPrice) => {
        let link_id;    
        try {
        const response = await instance.post(requests.paymentRequest, {
            customerName: name,
            customerPhone: phone,
            amount: planPrice,
            });
        if (response.data && response.data.link_url) {
            window.open(response.data.link_url, '_blank');
            link_id = response.data.link_id;

            const pollPaymentStatus = setInterval(async () => {
            try {
                const statusResponse = await instance.post(
                    requests.paymentSuccessful + link_id,
                    {
                      customerId: localStorage.getItem('user'),
                      customerPhone: phone,
                      amount: planPrice,
                    },
                );
                if (statusResponse.data.status === "success") {
                    clearInterval(pollPaymentStatus);
                    //close the payment page
                }
            } catch (error) {
                console.error("Error occurred while checking payment status:", error);
            }
            }, 5000);
        } else {
            alert("Network error! Please try again.");
        }
        } catch (error) {
        console.error("Error occurred during payment:", error);
        }

    };

    // Render each subscription plan
    const renderSubscriptionItem = (item) => (
        <div
            className="subscription-item"
            onClick={() => handleOnlinePayment(item.PlanPrice)}
        >
            <h2 className="title">{item.PlanName}</h2>
            <p className="description">{item.PlanDescription}</p>
            {item.Extras && <p className="extras">{item.Extras}</p>}
            <p className="price">₹ {item.PlanPrice}</p>
        </div>
    );

    const fetchActivePlan = async () => {
        try {
            const response = await instance.get(requests.fetchActivePlan + localStorage.getItem('user'));
            const data = response.data;
            setActivePlan(data);
        } catch (error) {
            console.error('Error fetching plans:', error);
        }
    };
    
    useEffect(() => {
        fetchActivePlan();
    }, []);

    useEffect(() => {
        async function fetchPlanList() {
            try {
                const response = await instance.get(requests.fetchSubscriptionPlans);
                const data = response.data;
                setPlans(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching plans:', error);
            }
        }

        fetchPlanList();
        fetchUserData();
    }, []);

    useEffect( () =>{
        async function fetchDeposit() {
          try {
            const response = await instance.post(requests.fetchDeposit, {
              userId: localStorage.getItem('user')
            });
            setDeposit(response.data.deposit);
            } catch (error) {
              console.log(error);
            }
      }
        fetchDeposit();
      }, []);

    if (loading) {
        return (
            <div className="container">
                <div className="shimmer-placeholder" />
                <div className="shimmer-placeholder" />
                <div className="shimmer-placeholder" />
            </div>
        );
    } else {
        return (
            <>
                <SEO
                    title="Subscriptions - Biblophile"
                    description="View the subscription plans for book rental service."
                    name='Biblophile'
                    type='website'
                    image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
                />
                <div className="subscription-container">
                    <div className="deposit-view">
                        <span className="deposit-label">Security deposit: </span>
                        <span className="price">₹ {deposit}</span>
                    </div>
                    {activePlan[0] && activePlan[0].PlanId &&
                        <>
                            <h1 className="heading">Active Plan</h1>
                            <div className="subscription-item">
                                <h2 className="title">{activePlan[0].PlanName}</h2>
                                <p className="description">{activePlan[0].PlanDescription}</p>
                                <div className="footer">
                                    <span className="price">₹ {activePlan[0].PlanPrice}</span>
                                    {activePlan[0].EndDate && <span className="deadline">Ends on: {activePlan[0].EndDate.split(" ")[0]}</span>}
                                </div>
                            </div>
                        </>
                    }
                    <h1 className="heading">Subscription Plans</h1>
                    <div className="subscription-list">
                        {plans.map(item => (
                            <React.Fragment key={item.PlanId}>
                                {renderSubscriptionItem(item)}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default SubscriptionPage;
