import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import instance from '../services/axios';
import requests from '../services/requests';
import './BookshelfCard.css';
import ReadingStatus from './ReadingStatus';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
};

const BookshelfCard = ({ id, photo, status, startDate, endDate, currentPage, product }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newStartDate, setNewStartDate] = useState(startDate);
  const [newEndDate, setNewEndDate] = useState(endDate);

  const handleSave = () => {
    if (newStartDate > newEndDate) {
      alert('Start Date cannot be after end date');
      return;
    }
    instance.post(requests.updateBookDates, {
      bookId: id,
      startDate: newStartDate,
      endDate: newEndDate
    })
    .then(response => {
      if (response.data.status === "success") {
        setIsEditing(false);
      } else {
        console.error(response.data.message);
      }
    })
    .catch(error => console.error(error));
  };

  const formattedStartDate = formatDate(newStartDate);
  const formattedEndDate = newEndDate ? formatDate(newEndDate) : "today";

  return (
    <div className="card-container">
      <NavLink to={`/product/?type=Book&id=${id}`} className="product_link">
        <div
          className="card-image-bg"
          style={{ backgroundImage: `url(${photo})` }}
        ></div>
      </NavLink>
      <div className="card-footer">
        <div className="card-footer-row">
          <ReadingStatus id={id} isGoogleBook={false} product={product} displayShare={false}/>
          {(status === 'Read' && startDate && endDate) ? (
            isEditing ? (
              <div className="date-edit-container">
                <input
                  className="date-input"
                  type="date"
                  value={newStartDate}
                  onChange={(e) => setNewStartDate(e.target.value)}
                />
                <input
                  className="date-input"
                  type="date"
                  value={newEndDate}
                  onChange={(e) => setNewEndDate(e.target.value)}
                />
                <div className="edit-buttons">
                  <TiTick color='#D17842' size={30} onClick={handleSave}/>
                  <RxCross2 color='#DC3535' size={30} onClick={() => setIsEditing(false)}/>
                </div>
              </div>
            ) : (
              <p className="card-footer-text" onClick={() => setIsEditing(true)}>
                {`${formattedStartDate} - ${formattedEndDate}`}
              </p>
            )
          ) : status === 'Currently reading' && startDate ? (
            isEditing ? (
              <div className="date-edit-container">
                <input
                  className="date-input"
                  type="date"
                  value={newStartDate}
                  onChange={(e) => setNewStartDate(e.target.value)}
                />
                <div className="edit-buttons">
                  <TiTick color='#D17842' size={30} onClick={handleSave}/>
                  <RxCross2 color='#DC3535' size={30} onClick={() => setIsEditing(false)}/>
                </div>
              </div>
            ) : (
              <p className="card-footer-text" onClick={() => setIsEditing(true)}>
                {`${formattedStartDate} - ${formattedEndDate}`}
              </p>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BookshelfCard;
