import React, { useState, useEffect } from 'react';
import instance from '../../services/axios';
import requests from '../../services/requests';
import './ReviewList.css';
import SEO from '../../services/seo';
import Mascot from '../Mascot';

const ReviewList = () => {
    const [reviews, setReviews] = useState([]);
    const [editing, setEditing] = useState(null);
    const [currentReview, setCurrentReview] = useState({ rating: '', review: '' });

    const userId = localStorage.getItem('user');

    useEffect(() => {
        // Fetch reviews on component mount
        instance.get(`${requests.fetchUserReviews}${userId}`)
            .then(response => {
                setReviews(response.data);
            })
            .catch(error => console.error("Error fetching reviews:", error));
    }, [userId]);

    const handleEdit = (review) => {
        setEditing(review.ratingId);
        setCurrentReview({ rating: review.rating, review: review.review });
    };

    const handleDelete = (ratingId, productId) => {
        const confirmed = window.confirm("Are you sure you want to delete this review?");
        if (confirmed) {
            instance.post(requests.updateUserReview, {
                userId,
                productId,
                actionType: 'delete'
            })
                .then(response => {
                    setReviews(reviews.filter(review => review.ratingId !== ratingId));
                    alert(response.data.message);
                })
                .catch(error => console.error("Error deleting review:", error));
        }
    };

    const handleSave = (ratingId, productId) => {
        instance.post(requests.updateUserReview, {
            userId,
            productId,
            rating: currentReview.rating,
            review: currentReview.review,
            actionType: 'update'
        })
            .then(response => {
                setReviews(reviews.map(review =>
                    review.ratingId === ratingId ? { ...review, rating: currentReview.rating, review: currentReview.review } : review
                ));
                setEditing(null);
                alert(response.data.message);
            })
            .catch(error => console.error("Error updating review:", error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentReview(prev => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <SEO
                title="Your reviews - Biblophile"
                description="View the ratings and reviews you have written for the books."
                name='Biblophile'
                type='website'
                image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
            />
            <div className="user-reviews">
                <h2 className="title">My Ratings & Reviews</h2>
                {reviews.length === 0 && <Mascot emotion="reading"/>}
                {reviews.map((review) => (
                    <div key={review.ratingId} className="review-card">
                        <img src={review.bookImage} alt={`${review.bookTitle} cover`} className="book-image" />
                        {editing === review.ratingId ? (
                            <>
                                <input
                                    type="number"
                                    name="rating"
                                    value={currentReview.rating}
                                    onChange={handleChange}
                                    className="rating-input"
                                    min="1"
                                    max="5"
                                />
                                <textarea
                                    name="review"
                                    value={currentReview.review}
                                    onChange={handleChange}
                                    className="review-input"
                                />
                                <button onClick={() => handleSave(review.ratingId, review.productId)} className="save-btn">Save</button>
                            </>
                        ) : (
                            <>
                                <div className="rating">Rating: {review.rating} / 5</div>
                                <p className="review-text">{review.review}</p>
                                <button onClick={() => handleEdit(review)} className="edit-btn">Edit</button>
                                <button onClick={() => handleDelete(review.ratingId, review.productId)} className="delete-btn">Delete</button>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default ReviewList;