import React, { useState, useEffect } from "react";
import "./Row.css";
import axios from "../services/axios";
import requests from "../services/requests";
import BookDetail from "./BookDetail";

function Row({ title, searchQuery, onResetSearch }) {
    const [books, setBooks] = useState([]);
    const [bookDetails, setBookDetails] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                let response;
                if (title === "Available results") {
                    response = await axios.get(requests.searchBooks + searchQuery);
                } else if (title === "Other results") {
                    response = await axios.get(requests.searchExternalBooks + searchQuery);
                } else {
                    response = await axios.get(requests.getBooks + title);
                }
                setBooks(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        if (searchQuery || title !== "Search results") {
            fetchData();
        }
    }, [searchQuery, title]);

    const handleClick = (book) => {
        setBookDetails(book);
    };

    const handleCloseDiv = () => {
        setBookDetails(null);
    };

    return (
        <div className="row">
            <h2 className={title === "Available results" || title === "Other results" ? "searched_row" : ""}>{title}</h2>
            <div className="row_posters">
                {books.map((book) => (
                    <img
                        key={book.BookId || book.GoogleBookId} 
                        onClick={() => handleClick(book)}
                        className="row_poster"
                        src={book.BookPhoto || book.volumeInfo?.imageLinks?.thumbnail}
                        alt={book.BookName || book.volumeInfo?.title} 
                    />
                ))}
            </div>
            {bookDetails && <BookDetail bookInfo={bookDetails} closeDiv={handleCloseDiv} onResetSearch={onResetSearch} />}
        </div>
    );
}

export default Row;
