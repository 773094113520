import React, { useState, useEffect } from 'react';
import axios from "../services/axios";
import requests from "../services/requests";

import "./BookEmotions.css";

function BookEmotions({ id, isGoogleBook, product }) {
    const [emotions, setEmotions] = useState([]);
    const [updateMessage, setUpdateMessage] = useState(null);

    async function fetchEmotions() {
        try {
        let bookIdToFetch = id;

        if (isGoogleBook) {
            // Fetch the BookId based on ISBN13 in case of google books
            const isbn = product.volumeInfo?.industryIdentifiers?.find(id => id.type === 'ISBN_13')?.identifier || '';
            if (isbn) {
                const bookIdResponse = await axios.post(requests.fetchBookId, { ISBN: isbn });
                if (bookIdResponse.data.bookId) {
                    bookIdToFetch = bookIdResponse.data.bookId;
                } else {
                    // setUpdateMessage("Failed to fetch BookId");
                    return;
                }
            }
        }

        const response = await axios.post(requests.fetchEmotions, {
            userId: localStorage.getItem('user'),
            bookId: bookIdToFetch,
        });

        setEmotions(response.data.map(emotion => ({ ...emotion, value: emotion.value })));
    } catch (error) {
        console.error('Error fetching emotinos:', error);
    }
    }

    useEffect(() => {
        fetchEmotions();
    }, [product]);

    const handleMoodChange = (index, newValue) => {
        const updatedMoods = [...emotions];
        updatedMoods[index].value = newValue;
        setEmotions(updatedMoods);
    };

    const handleEmotionSubmit = async () => {
        try {
            const userId = localStorage.getItem("user");
            const emotionsData = emotions.map(emotion => ({
                emotionId: emotion.emotionId,
                value: emotion.value
            }));
    
            let bookId = id;
    
            if (isGoogleBook) {
                // Add the book first in own db in case of external books
                const bookData = {
                    ISBN: product.volumeInfo?.industryIdentifiers?.find(id => id.type === 'ISBN_13')?.identifier || '',
                    Title: product.volumeInfo?.title || '',
                    Pages: product.volumeInfo?.pageCount || '',
                    Price: product.saleInfo?.listPrice?.amount || 0,
                    Description: product.volumeInfo?.description || '',
                    Authors: JSON.stringify(product.volumeInfo?.authors || []),
                    Genres: JSON.stringify(product.volumeInfo?.categories || []),
                    Image: product.volumeInfo?.imageLinks?.thumbnail || ''
                };
    
                const bookResponse = await axios.post(requests.addBook, bookData);
    
                if (bookResponse.data.message === "Book added/updated successfully") {
                    bookId = bookResponse.data.bookId;
                } else {
                    setUpdateMessage("Failed to add/update book");
                    return;
                }
            }
    
            // Submit the emotions
            const reviewData = {
                userId: userId,
                productId: bookId,
                emotions: emotionsData
            };
    
            const response = await axios.post(requests.submitEmotionScore, reviewData);
    
            if (response.data.message === "Updated") {
                setUpdateMessage("Updated successfully!");
            } else {
                setUpdateMessage("Uh oh! Please try again");
            }
        } catch (error) {
            console.error('Error submitting score:', error);
            setUpdateMessage("Uh oh! Please try again");
        }
    };
    

    return (
        <div className="book-emotions-container">
        {localStorage.getItem('user') ? (
            <>
                {updateMessage && <p className='update-message'>{updateMessage}</p>}
                {emotions.map((mood, index) => (
                    <div key={mood.emotionId} className="mood-slider">
                        <label className="mood-label">{mood.emotion}</label>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={mood.value}
                            onChange={(e) => handleMoodChange(index, e.target.value)}
                            className="mood-range"
                        />
                        <span className="mood-value">{mood.value}</span>
                    </div>
                ))}
                <button className="emotion-submit-button" onClick={handleEmotionSubmit}>
                    Update
                </button>
            </>
        ) : (
            <p className="login-prompt">Login to put a review.</p>
        )}
    </div>
    );
}

export default BookEmotions;