import React from "react";

import "./OrderDetail.css";

function OrderDetail( {item} ) {
    var orderStatus = "";
    if (item.OrderStatus === "1") {
        orderStatus = " initiated";
    }
    else if (item.OrderStatus === "2") {
        orderStatus = " confirmed";
    }
    else if (item.OrderStatus === "3") {
        orderStatus = " completed";
    }
    else if (item.OrderStatus === "4") {
        orderStatus = " returned";
    }
    else orderStatus = " rejected";

    return (
        <div className="order-detail-Container">
            <div className="bookPoster">
                <img
                    className="bookPosterImage"
                    src={item.OrderImage} alt="Book"/>
            </div>
            <div className="book-details">
                <div className="book-description">
                    <h1>{item.OrderItem}</h1>
                    <p><b>TotalAmount:</b> ₹ {item.OrderAmount} + delivery charges(as applicable)</p>
                </div>
                <div className="order-status">
                    <p><b>Order status:</b>{orderStatus}</p>
                    {item.OrderStatus !== '0' && <p><b>Payment status:</b> {item.PaymentStatus === "0" ? "unpaid" : "paid" }</p>}
                </div>
                <div className="dates">
                    {item.DeliveryDate !== null && <p><b>Delivery date:</b><br/> {item.DeliveryDate !== "0000-00-00" ? item.DeliveryDate : "To be updated" }</p>}
                    <div className="space" />
                    {item.DueDate !== "0000-00-00" && <p><b>Due date:</b><br/> {item.DueDate}</p>}
                </div>
            </div>
        </div>
    )
}

export default OrderDetail;