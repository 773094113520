import React, { useState, useEffect } from 'react';
import { FaShare } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import axios from "../services/axios";
import requests from "../services/requests";

import "./ReadingStatus.css";

function ReadingStatus({ id, isGoogleBook, product, displayShare=true }) {
    const [status, setStatus] = useState('To be read');
    const [currentPage, setCurrentPage] = useState('');
    const [updateMessage, setUpdateMessage] = useState(null);

    const sharePage = async () => {     //may not work on phones
        try {
            if (navigator.share) {
                await navigator.share({
                    title: document.title,
                    url: window.location.href
                });
            } else {
                throw new Error('Web Share API not supported');
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    };

    async function fetchReadingStatus() {
        if (localStorage.getItem("user")) {
            try {
                let bookIdToFetch = id;
        
                if (isGoogleBook) {
                    // Fetch the BookId based on ISBN13 in case of google books
                    const isbn = product.volumeInfo?.industryIdentifiers?.find(id => id.type === 'ISBN_13')?.identifier || '';
                    if (isbn) {
                        const bookIdResponse = await axios.post(requests.fetchBookId, { ISBN: isbn });
                        if (bookIdResponse.data.bookId) {
                            bookIdToFetch = bookIdResponse.data.bookId;
                        } else {
                            // setUpdateMessage("Failed to fetch BookId");
                            return;
                        }
                    }
                }
        
                const response = await axios.post(requests.fetchReadingStatus, {
                    userId: localStorage.getItem('user'),
                    bookId: bookIdToFetch,
                });
        
                if (response.data.status) {
                    setStatus(response.data.status);
                }
                
                if (response.data.currentPage) {
                    setCurrentPage(response.data.currentPage);
                }
                
            } catch (error) {
                console.error('Error fetching emotions:', error);
            }
        }
    }

    useEffect(() => {
        fetchReadingStatus();
    }, [product]);

    const submitReadingStatus = async () => {
        if (localStorage.getItem("user")) {
            try {
                const userId = localStorage.getItem("user");
        
                let bookId = id;
        
                if (isGoogleBook) {
                    // Add the book first in own db in case of external books
                    const bookData = {
                        ISBN: product.volumeInfo?.industryIdentifiers?.find(id => id.type === 'ISBN_13')?.identifier || '',
                        Title: product.volumeInfo?.title || '',
                        Pages: product.volumeInfo?.pageCount || '',
                        Price: product.saleInfo?.listPrice?.amount || 0,
                        Description: product.volumeInfo?.description || '',
                        Authors: JSON.stringify(product.volumeInfo?.authors || []),
                        Genres: JSON.stringify(product.volumeInfo?.categories || []),
                        Image: product.volumeInfo?.imageLinks?.thumbnail || ''
                    };
        
                    const bookResponse = await axios.post(requests.addBook, bookData);
        
                    if (bookResponse.data.message === "Book added/updated successfully") {
                        bookId = bookResponse.data.bookId;
                    } else {
                        setUpdateMessage("Failed to add/update book");
                        return;
                    }
                }

                const requestData = {
                    userId: userId,
                    bookId: bookId,
                    status: status,
                };
    
                if (status === "Currently reading") {
                    requestData.currentPage = currentPage;
                }
        
                const response = await axios.post(requests.submitReadingStatus, requestData);
        
                if (response.data.message === "Updated") {
                    setUpdateMessage("Updated successfully!");
                } else {
                    setUpdateMessage(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting score:', error);
                setUpdateMessage("Uh oh! Please try again");
            }
        }
        else {
            setUpdateMessage("Login to update reading status");
        }
    }

    return (
        <>
            {updateMessage && <p className='update-message'>{updateMessage}</p>}
            <div className="status-dropdown">
                <label htmlFor="status">Status: </label>
                <select id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="Read">Read</option>
                    <option value="Currently reading">Currently Reading</option>
                    <option value="To be read">To be Read</option>
                    <option value="Did not finish">Did not finish</option>
                    <option value="Remove">Remove</option>
                </select>
                <TiTick className='icon tick-icon' onClick={()=>submitReadingStatus()}/>
                {displayShare && <FaShare className='icon share-icon' onClick={sharePage}/>}
            </div>
            {status === 'Currently reading' && (
                <div className="page-number-input">
                    <label htmlFor="currentPage">Current Page:</label>
                    <input
                        id="currentPage"
                        type="number"
                        min={1}
                        value={currentPage}
                        onChange={(e) => setCurrentPage(e.target.value)}
                    />
                </div>
            )}
        </>
    )
}

export default ReadingStatus;